import React from 'react'

import Button from '@/components/Button/Button'

const NotFoundPage = () => {
  return (
    <main className='flex h-[100vh] w-full flex-col items-center justify-center px-4'>
      <div className='text-center'>
        <h1 className='heading-l-regular text-blue600'>404</h1>
        <h2 className='heading-s-regular mt-8 text-blue900'>Page introuvable</h2>
        <p className='body-s-regular mt-2 text-blue900'>
          Désolé, nous n'avons pas trouvé la page que vous recherchez.
        </p>
        <div className='mt-8 flex cursor-pointer items-center justify-center'>
          <Button link={{url: '/'}} label="Retour à l'accueil" />
        </div>
      </div>
    </main>
  )
}

export default NotFoundPage
